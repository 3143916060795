import AIG from "./AIG";
import PETDR from "./PETDR";
import DTC from "./DTC";

const routers = {
  AIG,
  PETDR,
  DTC
};

export default {
  get: name => routers[name],
};
