import Vue from "vue"
import VueRouter from "vue-router"
import { auth, firestore, } from "@/services/firebase.service";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home"),
    meta: { excluded: true, },
  },

  {
    path: "/preguntas-frecuentes",
    name: "Faqs",
    component: () => import("../views/Faqs"),
  },
  {
    path: "/Exclusiones",
    name: "exclusiones",
    component: () => import("../views/Exclusions"),
  },
  {
    path: "/somos",
    name: "Somos",
    component: () => import("../views/Are"),
  },

  {
    path: "/planes",
    name: "plans",
    component: () => import("../views/Plans"),
  },

  {
    path: "/terminos-y-condiciones",
    name: "terminosYcondiciones",
    component: () => import("../views/Terms&conditions"),
  },

  {
    path: "/solicitudes",
    name: "solicitudes",
    component: () => import("../views/Requests"),
  },

  {
    path: "/solicitar",
    name: "FormularioSolicitud",
    component: () => import("../views/RequestView"),
  },

  {
    path: "/contacto",
    name: "contactar",
    component: () => import("../views/Contact"),
  },


  {
    path: "/auth",
    redirect: "/auth/login",
    component: () => import("../views/auth"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("../views/login"),
      },
    ],
  },

  {
    path: "/index-customer",
    name: "IndexCustomer",
    component: () => import("../views/index-customer"),
  },
  {
    path: "/my-account",
    name: "MyAccount",
    component: () => import("../views/my-account"),
    meta: { requiresAuth: true, },
    children: [
      {
        path: "/",
        name: "Profile",
        component: () => import("../views/my-account/Profile.vue"),
      },
      {
        path: "quotes",
        name: "Quotes",
        component: () => import("../views/my-account/Quotes.vue"),
      },
      {
        path: "security",
        name: "Security",
        component: () => import("../views/my-account/Security.vue"),
      }
    ],
  },
  {
    path: "/checkout/:id",
    name: "Checkout",
    component: () => import("../views/checkout"),
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    component: () => import("../views/page-thankyou"),
  },

  {
    path: "/dev",
    name: "develop",
    component: () => import("../views/Development"),
  },

  {
    path: "*",
    component: () => import("../views/page-error"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  const user = auth.currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const excluded = to.matched.some(record => record.meta.excluded);

  if (excluded) {
    next();
    return;
  }

  if (!user && requiresAuth) {
    return next("/auth/login");
  } else if (user && !requiresAuth) {
    return next("/my-account");
  } else {
    return next();
  }

});

export default router
