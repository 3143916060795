<template>
  <div class="floating-button whatsapp">
    <a v-bind:href="phone" target="_blank">
      <img src="https://firebasestorage.googleapis.com/v0/b/dtc-2012.appspot.com/o/assets%2Fwhatsapp.svg?alt=media&token=cb28f069-d9cc-4548-815f-80f8997cf8a7"
        alt="Whatsapp icon"
        height="48">
    </a>
  </div>
</template>

<script>
  export default {
    props: {
      props: {
        type: Object,
        default: function() {
          return {};
        }
      }
    },
    computed: {
      phone() {
        if (this.props.phone)
          return `https://wa.me/${this.props.phone}`;
        return "javascript:;"
      }
    }
  };
</script>