export default {
  props: {
    tag: {
      type: String,
      default: "span",
    },
    text: String,
  },
  render(h) {
    const self = this;
    return h(self.tag, {
      domProps: {
        innerHTML: self.text,
      },
      on: {
        dblclick: function(event) {
          self.$emit("dblclick");
        }
      }
    });
  }
};