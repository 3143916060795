import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth";
import plans from "./plans";
import system from "./system";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    plans,
    system,
  },
});