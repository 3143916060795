import { firestore, } from "@/services/firebase.service";

export default {
  namespaced: true,
  state: {
    plans: [],
  },
  getters: {
    ordered: state => state.plans.sort((a, b) => a.price > b.price ? 1 : -1),
  },
  mutations: {
    ADD_PLAN: (state, payload) => state.plans.push(payload),
  },
  actions: {
    GET_PLANS: ({ commit, }) => new Promise((resolve, reject) => {
      firestore.collection("plans")
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => commit("ADD_PLAN", { id: doc.id, ...doc.data(), }));
          resolve();
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    }),
  },
};